<template>
  <el-form :inline="formInline" :model="selectModel" label-position="right">
    <el-form-item label="名称">
      <el-input v-model="selectModel.username" placeholder="名称" />
    </el-form-item>
    <!-- <el-form-item>
      <el-button type="primary" @click="queryTableData">查询</el-button>
    </el-form-item> -->
    <el-form-item class="main-form-item-float-right">
      <el-button-group>
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="showDialogForm({created:true})">新建</el-button>
      </el-button-group>
      <el-button-group>
        <el-button type="primary" icon="el-icon-search" @click="queryTableData">查询</el-button>
      </el-button-group>
    </el-form-item>
  </el-form>

  <el-table
    :data="tableData"
    border
    stripe
    :default-sort="defaultSort"
    @sort-change="sort"
    style="width: 100%;">
    <el-table-column label="ID" prop="id" width="60px" align="center" sortable="custom" />
    <el-table-column label="创建时间" prop="createTime" min-width="180px" />
    <el-table-column label="姓名" prop="username" min-width="200px" />
    <el-table-column
      fixed="right"
      label="操作"
      width="120">
      <template #default="scope">
        <el-button @click="showDialogForm({data: scope.row, readonly: true})" type="text" size="small">查看</el-button>
        <el-button @click="showDialogForm({data: scope.row})" type="text" size="small">编辑</el-button>
        <el-button @click="deleteAciton(scope.row)" type="text" size="small">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <div style="margin-top: 10px; text-align: right;">
    <el-pagination
      layout="prev, pager, next,jumper"
      :total="total"
      @current-change="applyCurrentPage" />
  </div>

  <el-dialog custom-class="main-el-dialog" :title="dialog.title" v-model="dialog.visibled" width="600px">
    <el-form :model="dialog.form" ref="dialogForm" label-width="80px">
      <el-form-item
        label="名称" prop="username"
        :rules="[
          { required: true, message: '名称不能为空', trigger: 'blur'}
        ]">
        <el-input v-model="dialog.form.username" :readonly="dialog.readonly" />
      </el-form-item>
      <el-form-item
        v-if="dialog.created"
        label="密码" prop="password"
        :rules="[
          { required: true, message: '密码不能为空', trigger: 'blur'}
        ]">
        <el-input v-model="dialog.form.password" :readonly="dialog.readonly" />
      </el-form-item>
      <el-form-item label="角色">
        <el-transfer
          class="dialog-el-transfer"
          v-model="dialog.form.roleIds"
          :props="{ key: 'id', label: 'des' }"
          :data="roles"
          :titles="['Source', 'Target']"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialog.visibled = false">取消</el-button>
        <el-button v-if="!dialog.readonly" type="primary" @click="dialog.submit">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { handleCatchStatus } from '../../api/handler';

export default {
  data() {
    return {
      selectModel: {},
      selectSort: null,
      defaultSort: {prop: 'id', order: 'descending'},
      tableData: [],
      total: 0,
      current: 1,
      formInline: true,
      dialog: {
        visibled: false,
        created: false,
        readonly: false,
        title: '',
        form: {},
        submit: () => true
      },
      roles: []
    };
  },
  created() {
    this.applySortSelect(this.defaultSort);
    this.queryTableData();
    this.$api.getAllAdminRole().then(data => {
      this.roles = data;
    });
  },
  mounted() {
  },
  methods: {
    queryTableData() {
      this.selectModel.sort = this.selectSort;
      this.selectModel.page = this.current - 1;
      this.$api.getAdminUserPage(this.selectModel)
        .then(data => {
          this.tableData = data.content;
          this.total = data.totalElements;
        });
      // .catch(handleCatchStatus)
    },
    sort({ prop, order }) {
      this.applySortSelect({ prop, order });
      this.queryTableData();
    },
    applySortSelect({ prop, order }) {
      if (order) {
        this.selectSort = `${prop},${order === 'ascending' ? 'ASC' : 'DESC'}`;
        return;
      }
      this.selectSort = null;
    },
    applyCurrentPage(current) {
      this.current = current;
      this.queryTableData();
    },
    showDialogForm({data, created = false, readonly = false}) {
      this.dialog.readonly = readonly;
      this.dialog.created = created;
      this.dialog.visibled = true;
      if (data) {
        this.dialog.form = { ...data };
        this.$api.getAllAdminRole({adminId: data.id}).then(responseData => {
          this.dialog.form.roleIds = responseData.map(item => item.id);
        });
      }

      let submit;
      if (created) {
        this.dialog.title = '创建';
        this.dialog.form = {};
        submit = () => this.$api.createAdminUser(this.dialog.form);
      } else {
        this.dialog.title = '修改';
        submit = () => this.$api.updateAdminUser(this.dialog.form);
      }

      if (readonly) {
        this.dialog.title = '详情';
        submit = () => false;
      }

      this.dialog.submit = () => this.validateForm(() => submit()
        .then(() => {
          this.$message.success('操作成功');
          this.dialog.visibled = false;
          this.queryTableData();
        })
        .catch(handleCatchStatus));
      if (data) {
        console.log(data);
      }
    },
    validateForm(successFn) {
      this.$refs.dialogForm.validate(valid => {
        if (valid) {
          successFn();
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.dialogForm.resetFields();
    },
    deleteAciton(data) {
      this.$confirm('此操作将永久删除该内容, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => this.$api.deleteAdminUser(data.id))
        .then(() => {
          this.$message.success('删除成功!');
          this.queryTableData();
        })
        .catch(() => this.$message.info('已取消删除'));
    }
  }
};
</script>
